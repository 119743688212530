import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/app';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '../components/utils/localStorageUtils';
import { IConfirmDialog } from '../components/dialogs/ConfirmDialog/types';

const version = process.env.REACT_APP_VERSION;

type Actions = ActionType<typeof types>;

interface IConfirmDialogState {
  formData?: IConfirmDialog;
  open: boolean;
}
interface IMenu {
  open: boolean;
}

export interface IAppState {
  readonly confirmDialog: IConfirmDialogState;
  readonly menu: IMenu;
  readonly theme: 'light' | 'dark';
}

const initialState: IAppState = {
  confirmDialog: {
    open: false,
  },
  menu: {
    open: false,
  },
  theme:
    (getFromLocalStorage(`theme_${version}`) as 'light' | 'dark') || 'light',
};

export default (
  state: IAppState = initialState,
  action: Actions
): IAppState => {
  switch (action.type) {
    case getType(types.confirmDialog): {
      return {
        ...state,
        confirmDialog: {
          formData: action.payload,
          open: true,
        },
      };
    }

    case getType(types.confirmDialogClose): {
      return {
        ...state,
        confirmDialog: {
          ...state.confirmDialog,
          open: false,
        },
      };
    }

    case getType(types.toggleMenu):
      return {
        ...state,
        menu: {
          open: action.payload,
        },
      };

    case getType(types.toggleTheme): {
      const theme = state.theme === 'dark' ? 'light' : 'dark';

      saveToLocalStorage(`theme_${version}`, theme);

      return { ...state, theme };
    }

    default:
      return state;
  }
};
