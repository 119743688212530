import { createAction } from 'typesafe-actions';

import { IConfirmDialog } from '../components/dialogs/ConfirmDialog/types';

enum Types {
  CONFIRM_DIALOG = '@@app/CONFIRM_DIALOG',
  CONFIRM_DIALOG_CLOSE = '@@app/CONFIRM_DIALOG_CLOSE',
  TOGGLE_MENU = '@@app/TOGGLE_MENU',
  TOGGLE_THEME = '@@app/TOGGLE_THEME',
}

export const confirmDialog = createAction(
  Types.CONFIRM_DIALOG,
  (props: IConfirmDialog) => props
)();

export const confirmDialogClose = createAction(Types.CONFIRM_DIALOG_CLOSE)();

export const toggleMenu = createAction(
  Types.TOGGLE_MENU,
  (open: boolean) => open
)();

export const toggleTheme = createAction(Types.TOGGLE_THEME)();
